import {mdiCalendarSyncOutline, mdiCheck, mdiClockOutline, mdiHelp, mdiRestore} from "@mdi/js"

export const dictStatuses = {
	NEW: "NEW",
	ACCEPTED: "ACCEPTED",
	REJECTED: "REJECTED",
	RESCHEDULED: "EDITED",
	REJECTED_RESCHEDULED: "REJECTED_EDITED"
}

export const dictRoles = {
	MANAGER: "Manager",
	EMPLOYEE: "Employee",
	TIMEKEEPER: "Timekeeper"
}

export function getStatusColor(status) {
	switch (status) {
		case dictStatuses.NEW:
			return "secondary"
		case dictStatuses.ACCEPTED:
			return "success"
		case dictStatuses.REJECTED:
			return "warning"
		case dictStatuses.REJECTED_RESCHEDULED:
			return "warning"
		case dictStatuses.RESCHEDULED:
			return "info"
		default:
			return ""
	}
}

export function getStatusText(status) {
	switch (status) {
		case dictStatuses.NEW:
			return "В обработке"
		case dictStatuses.ACCEPTED:
			return "Принята"
		case dictStatuses.REJECTED:
			return "Отклонена"
		case dictStatuses.REJECTED_RESCHEDULED:
			return "Отклонена"
		case dictStatuses.RESCHEDULED:
			return "Перенос отпуска"
		default:
			return "Неизвестно"
	}
}

export function getStatusIcon(status) {
	switch (status) {
		case dictStatuses.NEW:
			return mdiClockOutline
		case dictStatuses.ACCEPTED:
			return mdiCheck
		case dictStatuses.REJECTED:
			return mdiRestore
		case dictStatuses.REJECTED_RESCHEDULED:
			return mdiRestore
		case dictStatuses.RESCHEDULED:
			return mdiCalendarSyncOutline
		default:
			return mdiHelp
	}
}

export function getRoleColor(status) {
	switch (status) {
		case dictRoles.EMPLOYEE:
			return ""
		case dictRoles.MANAGER:
			return "primary"
		case dictRoles.TIMEKEEPER:
			return "secondary"
		default:
			return ""
	}
}

export const dictVacationTypes = {
	MAIN: "MAIN",
	ADDITIONAL: "ADDITIONAL"
}