import axios from "axios"
//import i18n from "@/plugins/i18n";
import {checkAuthorizationToken} from "./functions"
import store from "@/store"
import i18n from "@/plugins/i18n"
import AuthService from "@/services/auth.service";

const ApiSecure = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	//params: new URLSearchParams({ lang: i18n.locale })
})

ApiSecure.interceptors.request.use(async function (config) {
	return checkAuthorizationToken(config)
}, function (error) {
	console.error("request rejected", error)
})

ApiSecure.interceptors.response.use(async function (response) {
	if (response.status === 200 || response.status === 201)
		return response.data
	return null
}, async function (payload) {
	if (payload.config.SILENT) return
	let snackbarMessage = i18n.t("snackbar.message-default")
	console.error("response rejected", payload.response)
	if (payload.response.data && payload.response.status !== 500) {
		const errors = payload.response?.data?.errors
		// for each error in errors array add a new line in snackbar message in the format "field: comment"
		if (errors) {
			snackbarMessage = ""
			for (const error of errors) {
				snackbarMessage += `${error.field}: ${error.message}\n`
			}
		}
	}
	await store.dispatch("App/setSnackbar", { message: snackbarMessage, type: "error", timeout: -1 })
	if (payload.response.status === 401) {
		await AuthService.logout()
	}
})

export default ApiSecure
