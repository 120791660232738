<script>
import {mdiAccountCircleOutline} from "@mdi/js"
import {dictRoles, getRoleColor} from "@/services/mappers"

export default {
	name: "CardUser",
	props: {
		user: {
			type: Object,
			required: true,
		},
		xSmall: {
			type: Boolean,
		},
		small: {
			type: Boolean,
		},
		label: {
			type: Boolean,
		},
		short: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			dictRoles,
			mdiAccountCircleOutline,
		}
	},
	computed: {
		nameDisplay() {
			const surname = this.user.lastName || this.user.last_name
			const name = this.user.firstName || this.user.first_name
			const middlename = this.user.middleName || this.user.middle_name
			return `${surname || 'Фамилия'} ${name || 'Имя'} ${middlename || 'Отчество'}`
		},
		nameDisplayShort() {
			const surname = this.user.lastName || this.user.last_name
			const name = this.user.firstName || this.user.first_name
			const middlename = this.user.middleName || this.user.middle_name
			return `${surname || 'Фамилия'} ${name ? name[0] + '.' : 'И.'} ${middlename ? middlename[0] + '.' : 'О.'}`
		},
		roleDisplay() {
			switch(this.user.role) {
				case dictRoles.EMPLOYEE:
					return "Сотрудник"
				case dictRoles.MANAGER:
					return "Менеджер"
				case dictRoles.TIMEKEEPER:
					return "Табельщик"
				default:
					return "Неизвестно"
			}
		}
	},
	methods: {getRoleColor}
}
</script>

<template>
	<v-menu
		:max-width="500"
		bottom
		offset-y
		:nudge-bottom="5"
	>
		<template #activator="{on, attrs}">
			<v-chip
				:small="small"
				:x-small="xSmall"
				:label="label"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon
					class="mr-2"
					x-small
				>
					{{ mdiAccountCircleOutline }}
				</v-icon>
				{{ short ? nameDisplayShort : nameDisplay }}
			</v-chip>
		</template>
		<v-list three-line>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-subtitle>
						<strong>Пользователь</strong>
						<v-chip
							x-small
							label
							class="ml-2"
						>
							#{{ user.id || 'xxxxxxx' }}
						</v-chip>
						<v-chip
							x-small
							class="ml-2"
							:outlined="user.role === dictRoles.EMPLOYEE"
							:color="getRoleColor(user.role)"
						>
							{{ roleDisplay }}
						</v-chip>
					</v-list-item-subtitle>
					<v-list-item-title>
						{{ nameDisplay }}
					</v-list-item-title>
					<v-list-item-subtitle>
						{{ user.email }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-subtitle>
						<strong>Должность и обособленное подразделение</strong>
					</v-list-item-subtitle>
					<v-list-item-title>
						<template v-if="user.position && user.position !== 'nan'">
							{{ user.position }}
						</template>
						<span
							v-else
							class="font-italic"
						>
							Позиция не указана
						</span>
					</v-list-item-title>
					<v-list-item-subtitle>
						<template v-if="user.department && user.department !== 'nan'">
							{{ user.department }}
						</template>
						<span
							v-else
							class="font-italic"
						>
							Подразделение не указано
						</span>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<slot	/>
	</v-menu>
</template>