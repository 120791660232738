const accessTokenKey  = "at"
const refreshTokenKey = "rt"
const accessExpiryKey = "ae"
const refreshExpiryKey = "re"
const emailKey = "em"

class WebStorage {
	constructor() {
		if (typeof localStorage === "object") {
			try {
				localStorage.setItem("localStorage", "item")
				localStorage.removeItem("localStorage")
				this.storage = localStorage
			} catch (e) {
				// //We're going to detect this and just silently drop any calls to setItem
				// //to avoid the entire page breaking, without having to do a check at each usage of Storage.
				// Storage.prototype._setItem = Storage.prototype.setItem;
				// Storage.prototype.setItem = function() {};

				alert("Your web browser does not support storing settings locally. " +
                  "Some settings may not save or some features may not work properly for you. " +
                  "If you are using \"Private Browsing Mode\", " +
                  "you can disable it and try to access this website again.")
			}
		} else {
			alert("Your web browser does not support storing settings locally. " +
              "Some settings may not save or some features may not work properly for you.")
		}
	}
	// GETTERS
	getAccessToken() {
		return JSON.parse(this.storage.getItem(accessTokenKey))
	}
	getRefreshToken() {
		return JSON.parse(this.storage.getItem(refreshTokenKey))
	}
	getAccessExpiry() {
		return JSON.parse(this.storage.getItem(accessExpiryKey))
	}
	getRefreshExpiry() {
		return JSON.parse(this.storage.getItem(refreshExpiryKey))
	}
	getEmail() {
		return JSON.parse(this.storage.getItem(emailKey))
	}


	// SETTERS
	setAccessToken(accessToken) {
		this.storage.setItem(accessTokenKey, JSON.stringify(accessToken))
	}
	setRefreshToken(refreshToken) {
		this.storage.setItem(refreshTokenKey, JSON.stringify(refreshToken))
	}
	setAccessExpiry(accessExpiry) {
		this.storage.setItem(accessExpiryKey, JSON.stringify(accessExpiry))
	}
	setRefreshExpiry(refreshExpiry) {
		this.storage.setItem(refreshExpiryKey, JSON.stringify(refreshExpiry))
	}
	setEmail(v) {
		this.storage.setItem(emailKey, JSON.stringify(v))
	}

	// REMOVERS
	clear() {
		this.removeAccessToken()
		this.removeRefreshToken()
		this.removeAccessExpiry()
		this.removeRefreshExpiry()
	}
	removeAccessToken() {
		this.storage.removeItem(accessTokenKey)
	}
	removeRefreshToken() {
		this.storage.removeItem(refreshTokenKey)
	}
	removeAccessExpiry() {
		this.storage.removeItem(accessExpiryKey)
	}
	removeRefreshExpiry() {
		this.storage.removeItem(refreshExpiryKey)
	}
	removeEmail() {
		this.storage.removeItem(emailKey)
	}
}

export default new WebStorage()