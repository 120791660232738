const routes =
	[
		{
			path: "/",
			name: "Start",
			components: {
				content: () => import("@/views/StartView.vue"),
			},
			meta: {
				public: false,
			}
		},
		{
			path: "/auth",
			name: "Auth",
			components: {
				content: () => import("@/views/AuthView.vue"),
			},
			meta: {
				title: "pages.auth.title",
				public: true,
			}
		},
		{
			path: "/manager",
			name: "Manager",
			components: {
				content: () => import("@/views/ManagerView.vue"),
			},
			meta: {
				title: "pages.manager.title",
				permission: "MANAGER_VIEW",
				public: false,
			}
		},
		{
			path: "/employee",
			name: "Employee",
			components: {
				content: () => import("@/views/EmployeeView.vue"),
			},
			meta: {
				title: "pages.employee.title",
				permission: "EMPLOYEE_VIEW",
				public: false,
			}
		},
		{
			path: "/timekeeper",
			name: "Timekeeper",
			components: {
				content: () => import("@/views/TimekeeperView.vue"),
			},
			meta: {
				title: "pages.timekeeper.title",
				permission: "TIMEKEEPER_VIEW",
				public: false,
			}
		},
		{
			path: "/404",
			name: "NotFound",
			components: {
				content: () => import("@/views/404.vue"),
			},
			meta: {
				public: true,
				title: "pages.404.title",
			}
		},
		{
			path: "*",
			redirect: "/404"
		}
	]

export default routes