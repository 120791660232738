<script>
import {mapGetters, mapState} from "vuex"
import {mdiLogout, mdiAccountOutline, mdiClipboardAccountOutline, mdiAccountTieOutline} from "@mdi/js"
import CardUser from "@/components/Card/CardUser.vue"
export default {
	name: "App",
	components: {
		CardUser,
		Snackbar: () => import("@/components/Snackbar"),
	},
	data: function () {
		return {
			mdiLogout,
		}
	},
	computed: {
		...mapState("App", { isRouterLoading: "isRouterLoading" }),
		...mapGetters("App", { isScreenSmall: "smallScreen" }),
		...mapGetters("User", {
			isLoggedIn: "isLoggedIn",
			user: "getUser",
			permissions: "getPermissions",
		}),
		showAppBar() {
			return this.isLoggedIn && this.$route.name !== 'Auth' && this.$route.name !== 'Start'
		},
		viewSwitcher() {
			return [
				{
					role: "Employee",
					permission: "EMPLOYEE_VIEW",
					icon: mdiAccountOutline,
					title: "Перейти в режим сотрудника",
					route: {name: "Employee"},
				},
				{
					role: "Manager",
					permission: "MANAGER_VIEW",
					icon: mdiAccountTieOutline,
					title: "Перейти в режим менеджера",
					route: {name: "Manager"},
				},
				{
					role: "Timekeeper",
					permission: "TIMEKEEPER_VIEW",
					icon: mdiClipboardAccountOutline,
					title: "Перейти в режим табельщика",
					route: {name: "Timekeeper"},
				},
			]
		},
		viewSwitcherDisplay() {
			return this.viewSwitcher.filter(({permission, route}) =>
				this.permissions.includes(permission) && this.$route.name !== route.name
			)
		},
	},
	created () {
		this.onResize()
	},
	methods: {
		onResize () {
			this.$store.commit("App/MENUS", false)
			setImmediate(() => {
				this.$store.commit("App/ON_RESIZE")
				this.$store.commit("App/MENUS", true)
			})
		},
		logout() {
			this.$auth.logout()
		}
	},
}
</script>

<template>
	<v-app v-resize="onResize">
		<v-app-bar
			v-if="showAppBar"
			app
			absolute
			flat
			min-height="56px"
			max-height="64px"
			style="border-bottom: #d2d2d7 solid 2px !important;"
		>
			<router-link to="/">
				<v-img
					alt="VSM Vacation"
					src="@/assets/logo/logo_c.png"
					width="100"
					contain
				/>
			</router-link>
			<strong
				class="ml-4"
				style="color: #666666; font-size: 14px"
			>
				Планер отпусков
			</strong>
			<v-spacer />
			<CardUser
				v-if="isLoggedIn"
				:user="user"
				label
			>
				<v-divider style="background: white" />
				<v-list>
					<v-list-item
						v-for="({route, icon, title}, indexViewSwitch) in viewSwitcherDisplay"
						:key="`view_switch_${indexViewSwitch}`"
						@click="$router.push(route)"
					>
						<v-list-item-avatar :size="24">
							<v-icon>
								{{ icon }}
							</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>
								{{ title }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item
						@click="logout"
					>
						<v-list-item-avatar :size="24">
							<v-icon>
								{{ mdiLogout }}
							</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>
								Выйти
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</CardUser>
		</v-app-bar>
		<template
			v-if="isRouterLoading"
		>
			<v-main>
				<v-overlay>
					<v-progress-circular
						indeterminate
						size="64"
					/>
				</v-overlay>
			</v-main>
		</template>
		<template v-else>
			<Snackbar />
			<v-main>
				<router-view
					:key="$route.fullPath"
					name="content"
				/>
			</v-main>
		</template>
	</v-app>
</template>