import i18n from "@/plugins/i18n"

const initialState = () => ({
	isRouterLoading: false,
	isContentLoading: false,
	isSwitchingLanguage: false,
	showMenus: true,
	width: 0,
	height: 0,
	mobileBreakpoint: 600,
	snackbar: false,
	snackbarMessage: "",
	snackbarType: "",
	snackbarTimeout: 4000,
	snackbarTimeoutID: null,
	disableCreateAndEdit: false,
	currentYear: 2025
})


/* Module .store.js */


// VUEX STATE
const state = initialState()


// VUEX GETTERS
const getters = {
	smallScreen(state) {
		return state.width < state.mobileBreakpoint
	},
	contentHeight(state) {
		return state.height - (state.width > 960 ? 64 : 56)
	},
	isReady(state) {
		return !state.isContentLoading && !state.isRouterLoading
	},
	getMobileBreakpoint(state) {
		return state.mobileBreakpoint
	},
}


// VUEX ACTIONS
const actions = {
	reset({commit}) {
		commit("RESET")
	},
	router({commit}, value) {
		commit("ROUTER_LOADING", value)
	},
	snackbarReset({commit, state}) {
		if (state.snackbarMessage || state.snackbarType)
			commit("SNACKBAR", {
				value: false,
				message: "",
				type: ""
			})
	},
	snackbarHide({commit, state}) {
		if (state.snackbar && (state.snackbarMessage || state.snackbarType))
			commit("SNACKBAR", {
				value: false,
				message: state.snackbarMessage,
				type: state.snackbarType
			})
	},
	async snackbarResetWithDelay({dispatch, state}) {
		clearTimeout(state.snackbarTimeoutID)
		await dispatch("snackbarHide")
		setTimeout(async () => {
			await dispatch("snackbarReset")
		}, 100)
	},
	async setSnackbar({commit, dispatch}, value) {
		if (value === false)
			await dispatch("snackbarResetWithDelay")
		else {
			clearTimeout(state.snackbarTimeoutID)
			const message = value.message || i18n.t("snackbar.message-default")
			const type = value.type || "info"
			const timeout = value.timeout || 4000
			commit("SNACKBAR", {
				value: true,
				message,
				type,
				timeout
			})
			if (timeout === -1) return
			const timeoutID = setTimeout(async () => {
				await dispatch("snackbarResetWithDelay")
			}, timeout)
			commit("SNACKBAR_TIMEOUT_ID", timeoutID)
		}
	}
}


// VUEX MUTATIONS
const mutations = {
	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	ROUTER_LOADING(state, value) {
		state.isRouterLoading = value
		state.showMenus = !value
	},
	ON_RESIZE(state) {
		if (process.server) return
		state.width = window.innerWidth
		state.height = window.innerHeight
	},
	DATA_LOADING(state, value) {
		state.isContentLoading = value
	},
	SWITCHING_LANGUAGE(state, value) {
		state.isSwitchingLanguage = value
	},
	MENUS(state, value) {
		state.showMenus = value
	},
	SNACKBAR(state, {value, message, type, timeout}) {
		state.snackbar = value
		state.snackbarMessage = message
		state.snackbarType = type
		state.snackbarTimeout = timeout
	},
	SNACKBAR_TIMEOUT_ID(state, value) {
		state.snackbarTimeoutID = value
	}
}


export default {
	state,
	getters,
	actions,
	mutations
}