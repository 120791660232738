import ApiSecure from "@/services/api/api-secure"
import ApiDoc from "@/services/api/api-doc"
export function getUserInfo() {
	return ApiSecure.get("profile/self/")
}
export function getVacationApplications() {
	return ApiSecure.get("vacation_applications/", {  params: { is_deleted: false } })
}

export function getVacationApplication(id) {
	return ApiSecure.get(`vacation_applications/${id}/`)
}

export function postVacationApplication(data) {
	return ApiSecure.post("vacation_applications/", data)
}

export function putVacationApplication(id, data) {
	return ApiSecure.put(`vacation_applications/${id}/`, data)
}

export function postRejectVacationApplication(id, data) {
	return ApiSecure.post(`vacation_applications/${id}/reject/`, data)
}

export function postRejectRescheduleVacationApplication(id, data) {
	return ApiSecure.post(`vacation_applications/${id}/reject_edited/`, data)
}

export function postAcceptVacationApplication(id, data) {
	return ApiSecure.post(`vacation_applications/${id}/accept/`, data)
}

export function getVacationApplicationGantt() {
	return ApiSecure.get("/vacation_applications/gantt/")
}

export function getVacations() {
	return ApiSecure.get("vacations/")
}

export function getVacation(id) {
	return ApiSecure.get(`vacations/${id}/`)
}

export function postVacation(data) {
	return ApiSecure.post("vacations/", data)
}

export function putVacation(id, data) {
	return ApiSecure.put(`vacations/${id}/`, data)
}

export function getHolidays(year) {
	return ApiSecure.get(`calendar/${year}/holidays/`)
}

export function getVacationApplicationsForManager() {
	return ApiSecure.get("manager/", {  params: { is_deleted: false } })
}

export function getVacationApplicationsForTimekeeper() {
	return ApiSecure.get("timekeeper/list_vacation_applications/", {  params: { is_deleted: false } })
}

export function getEmployeesForTimekeeper() {
	return ApiSecure.get("timekeeper/list_employees/")
}

export function uploadTimekeeperEmployeeList(data) {
	return ApiSecure.post("timekeeper/template/", data)
}

export function timekeeperTemplateDownload() {
	return ApiDoc.get("timekeeper/template/")
}

export function rescheduleVacationApplication(id, data) {
	return ApiSecure.put(`vacation_applications/${id}/edit/`, data)
}

export function getVacationApplicationRescheduleDoc(id) {
	return ApiDoc.get(`vacation_applications/${id}/get_vacation_document/`)
}